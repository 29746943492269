import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PasswordPromptComponent } from './password-prompt.component';
import { PasswordPromptService } from './password-prompt.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [PasswordPromptComponent],
  providers: [PasswordPromptService],
  exports: [PasswordPromptComponent]
})
export class PasswordPromptModule { }
