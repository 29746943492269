import { Injectable } from '@angular/core';

@Injectable()
export class PasswordPromptService {

  display;
  resolve;

  password;

  prompt() {
    delete this.password;
    this.display = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }
}
