import { Injectable } from '@angular/core';

@Injectable()
export class ConfirmService {

  action;
  object;
  headline;
  details;
  color;

  display;
  resolve;

  confirm(object?, action?, details?, color?) {

    this.object = object ? object : 'item';
    this.action = action ? this.capitalize(action) : 'Delete';
    this.headline = this.action + ' this ' + this.object + '?';
    this.details = details ? details : 'Are you sure you want to do this? This action is irreversible';
    this.color = color ? color : 'danger';
    this.display = true;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  logout() {
    this.headline = 'Do you really want to logout ?';
    this.action = 'Logout';
    this.color = 'danger';
    this.display = true;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
