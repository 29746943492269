import { Component } from '@angular/core';
import { PasswordPromptService } from './password-prompt.service';

@Component({
  selector: 'app-password-prompt',
  templateUrl: './password-prompt.component.html',
  styleUrls: ['./password-prompt.component.scss']
})
export class PasswordPromptComponent {

  constructor(public passwordPrompt: PasswordPromptService) { }
}
