<div class="confirm" *ngIf="confirm.display">
  <div class="frame" [class]="confirm.color">

    <!-- Content -->
    <div class="content">
      <div class="icon">!</div>
      <div class="headline">{{ confirm.headline }}</div>
      <div class="details">{{ confirm.details }}</div>
    </div>

    <!-- Commands -->
    <div class="commands">
      <button class="subtle" (click)="confirm.display = false; confirm.resolve(false)">Cancel</button>
      <button class="primary" (click)="confirm.display = false; confirm.resolve(true)">{{ confirm.action }}</button>
    </div>

  </div>
</div>
