import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ConfirmService } from './../confirm/confirm.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  mobile;
  expanded = true;

  constructor(
    public auth: AuthService,
    private confirm: ConfirmService,
    public router: Router
  ) { }

  ngOnInit() {
    this.mobile = window.innerWidth <= 640;
    if (!this.mobile) {
      const value = localStorage.getItem('stasboard-menu');
      this.expanded = value !== null ? (value === 'true') : this.expanded;
    } else {
      this.expanded = false;
    }
  }

  toggleMenu() {
    this.expanded = !this.expanded;
    localStorage.setItem('stasboard-menu', this.expanded.toString());
  }

  async logout() {
    try {
      if (await this.confirm.logout()) {
        this.auth.logout();
      }
    } catch (err) {
      console.error(err);
    }
  }
}
