import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class LoadingService {

    isLoading = false as boolean;

    constructor(private router: Router) {
        router.events.subscribe((val) => {
        });
    }

    start() {
        this.isLoading = true;
    }

    stop() {
        this.isLoading = false;
    }
}
