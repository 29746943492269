import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private user;

  categories$: Observable<any[]>;
  transactions$: Observable<any[]>;

  categories: any[];
  transactions: any[];

  constructor(
    private auth: AuthService,
    private firestore: AngularFirestore
  ) {

    this.transactions$ = this.auth.user$.pipe(
      switchMap(user => {
        if (user && user.bankAccount) {
          this.user = user;
          return this.firestore
            .collection(`users/${user.uid}/transactions/`, ref => ref.where('BankAccount', '==', user.bankAccount).where('DebitAmount', '!=', null))
            .valueChanges();
        } else {
          return of(null);
        }
      })
    );

    this.transactions$.subscribe(transactions => {
      console.log('Transactions changed: ' + (transactions ? transactions.length : 0));
      this.transactions = transactions ? transactions.sort((b, a) => (a.Date > b.Date) ? 1 : ((b.Date > a.Date) ? -1 : 0)) : undefined;
    });

    this.categories$ = this.auth.user$.pipe(
      switchMap(user => {
        if (user && user.bankAccount) {
          return this.firestore.collection(`users/${user.uid}/categories/`, ref => ref.orderBy('name')).valueChanges();
        } else {
          return of(null);
        }
      })
    );

    this.categories$.subscribe(categories => {
      this.categories = categories;
    });
  }

  changeCategory(transactionKey: string, category: string): Promise<any> {
    return this.firestore.doc(`users/${this.user.uid}/transactions/${transactionKey}`).set({ category: category }, { merge: true });
  }

  addCategoryRule(name: string, keyword: string) {
    return this.firestore.collection(`users/${this.user.uid}/categories`).doc().set({ name, keyword });
  }
}
