<div class="menu vertical" [class.expanded]="expanded">
  <div class="header">
    <img src="./assets/img/stasboard-white.svg">
    <div class="icon" (click)="toggleMenu()" *ngIf="!expanded"><i class="far fa-bars"></i></div>
    <div class="icon" (click)="toggleMenu()" *ngIf="expanded"><i class="far fa-times"></i></div>
  </div>
  <div class="links" [class.expanded]="expanded" (click)="expanded = false || ( !mobile && expanded )">
    <a [routerLink]="['/dashboard']" [class.selected]="router.url.startsWith('/dashboard')">
      <div class="icon"><i class="far fa-chart-line"></i></div><span>Dashboard</span>
    </a>
    <a [routerLink]="['/statements']" [class.selected]="router.url.startsWith('/statements')">
      <div class="icon"><i class="fas fa-list"></i></div><span>Statements</span>
    </a>
    <a [routerLink]="['/reconciliation']" [class.selected]="router.url.startsWith('/reconciliation')">
      <div class="icon"><i class="fas fa-link"></i></div><span>Reconciliation</span>
    </a>
    <a [routerLink]="['/categories']" [class.selected]="router.url.startsWith('/categories')">
      <div class="icon"><i class="fas fa-object-group"></i></div><span>Categories</span>
    </a>
    <a [routerLink]="['/transactions']" [class.selected]="router.url.startsWith('/transactions')">
      <div class="icon"><i class="fas fa-stream"></i></div><span>Transactions</span>
    </a>
    <a (click)="logout()">
      <div class="icon"><i class="i icon-logout"></i></div><span>Logout</span>
    </a>
  </div>
  <div class="toggle" (click)="toggleMenu()">
    <div class="icon" *ngIf="!expanded"><i class="fas fa-chevron-right fa-fw"></i></div>
    <div class="icon" *ngIf="expanded"><i class="fas fa-chevron-left fa-fw"></i></div>
  </div>
</div>