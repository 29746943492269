<div class="confirm" *ngIf="passwordPrompt.display">
  <div class="frame info">

    <!-- Content -->
    <div class="content">
      <div class="icon">?</div>
      <div class="headline">Enter your Password... </div>
      <input type="password" [(ngModel)]="passwordPrompt.password" placeholder="Password... " />
    </div>

    <!-- Commands -->
    <div class="commands">
      <button class="subtle" (click)="passwordPrompt.display = false; passwordPrompt.resolve(undefined); passwordPrompt.password = undefined;">Cancel</button>
      <button class="primary" (click)="passwordPrompt.display = false; passwordPrompt.resolve(passwordPrompt.password); passwordPrompt.password = undefined;">OK</button>
    </div>

  </div>
</div>