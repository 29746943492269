import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ConfirmComponent],
    providers: [ConfirmService],
    exports: [ConfirmComponent]
})
export class ConfirmModule { }
